.App {
  /* background-image: url("bg.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat; */
  /* background-color: rgb(0.8, 5, 61); */

  margin: 0;
}

/* @media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
  }
} */

.App a {
  text-decoration: none;
}

.App main {
  padding: 0;
}

.App main .container {
  padding: 1em;
}

.App .serviceIcons {
  background: rgb(0, 65, 165);
}

.App .sticky-top {
  z-index: 1;
}

.App .serviceIcons {
    padding: 0;
    margin-bottom: 1em;
}

.App .serviceIcons .container {
    padding: 0 1em;
}