/* #mainSlider {
  background-color: rgba(125, 125, 125, 0.8);
} */

#mainSlider img {
  width: 100%;
}

#mainSlider .slidercaption {
  font-size: 1.2em;
  font-weight: 600;
}

@media (min-width: 768px) {  
  #mainSlider .slidercaption {
    font-size: 2em;
  }   
}