.scroll {
  opacity: 0.5;
  /* background-color: lightblue; */
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  border-color: blue;
  z-index: 1;
}

.scroll:hover {
  opacity: 1;
}