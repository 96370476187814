html {
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
  width: 100%;
}

.center-screen {
  align-items: center;
  min-height: 100vh;
}