.serviceIcon a {
    color: white;
}

.serviceIcon img {
    background: white;
}

.serviceIcon svg {
    color: black;
    background-color: white;
}

.serviceIcon .card {
    background-color: inherit;
    border: none;
    margin: 0;
    padding: 1em 0 0;
    width: 160px;
}

.serviceIcon .card-body {
    background-color: inherit;
    border: none;
    text-align: center;
    margin: 0;
    padding: 0;
}

.serviceIcon .card-footer {
    background-color: inherit;
    border: none;
    text-align: center;
    margin: 0;
    padding: 0;
}

.serviceIcon .service-title {
    margin: .5em;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

.serviceTitleIcon {
    width: 24px;
}