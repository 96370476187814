footer {
  font-size: 14px;
  /* background-color: rgb(75, 75, 75); */
  background-color: rgb(0, 57.6, 86.7);
  background-image: url("map-image.png");
}

#footer .container {
  padding: 1em 3em 0;
  background-color: initial;
}

#footer .menus {
  padding: 1em;
  color: white;
}

#footer .copyright {
  background-color: white;
}

/* #footer .menus .container {
  background-color: inherit;
} */

#footer a {
  color: white;
}

#footer a:hover {
  color: orange;
}

#footer .menuBlock ul {
  padding-left: 1em;
}

#footer .company {
  color: white;
  text-transform: uppercase;
}

#footer .phone {
  font-size: large;
}

#footer .contact  {
  color: white;
}
#footer .contact p {
  line-height: 1em;
}

#footer .poweredby a {
  color: blue;
}

#footer .poweredby a:hover {
  color: orange;
}