/* .service .container {
  background: rgba(75, 75, 75, 0.5);
} */

.service .description {
  /* font-size: larger; */
  font-weight: 400;
  font-family: "Roboto", Sans-serif;
}

.service .carousel-inner img {
  margin: auto;
  cursor: pointer;
}

/* .service .card, .card-header, .card-body, .card-footer {
  background-color: inherit;
} */

.service .card {
  border: none;
  /* padding: 1em; */
  margin-bottom: 1em;
}

.service .card-header {
  background-color: transparent;
  border: none;
  padding: 0;
  border-bottom-color: rgb(0.8, 5, 61);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-radius: 0;
}

.service .card-body {
  padding: 0;
}

.service ul {
  padding: 0.5em 1.5em 0;
}

/* .service .card-footer {
  border: none;
  padding: 1em 0 0;
} */

/* .service .card-footer ul {
  margin-bottom: 0;
} */

.service .carousel {
  background-color: rgb(0, 65, 165);;
}