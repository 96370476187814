#quotation {
  margin-bottom: 1em;
  background-color: lightgray;
  /* border: solid; */
  border-radius: 0.5em;
  box-shadow: 5px 10px 18px darkgray;
}

#quotation h4 {
  text-align: center;
}

#quotation .container {
  background-color: inherit;
  border: none;
}

#quotation .attachmentNote {
  font-family: "Roboto", Sans-serif;
  font-size: smaller;
  margin-bottom: 1em;
  padding: 0.3em;
}

#quotation .spinner-border-sm {
  margin-right: 0.5em;
}

#quotation .status {
  margin-top: 1em;
}
