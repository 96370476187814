#pastjobs section {
  margin-bottom: 1em;
}

#pastjobs .carousel {
  background-color: rgb(0, 65, 165);;
}

#pastjobs .carousel-inner img {
  margin: auto;
  cursor: pointer;
}

#pastjobs .caption {
  color: blue;
  padding: 0 2px;
  font-size: 1.6em;
  font-weight: 600;
}
