header {
  background-color: white;
}

header .banner {
  padding: 0;
}

header .banner .slogan {
  color: blue;
  font-size: .7em;
  font-weight: 600;
  min-width: 110px;
  text-align: right;
}

header .banner .langswitch button {
  font-size: xx-small;
  height: 2em;
  padding: 0 .5em;
}

@media (min-width: 768px) {  
  header .banner .contact {
    min-width: 220px;
  }
  header .banner .slogan {
    font-size: 1.2em;
  }   
  header .banner .langswitch button {
    font-size: .7em;
    height: 2.2em;
  }
  #header .socialmedia svg {
    width: 24px;
  }
}

@media (min-width: 1024px) {
  header .banner .contact {
    min-width: 380px;
  }
}

@media (min-width: 992px) {  
  header .banner .slogan {
    font-size: 1.4em;
  }   
}

header .phone a {
  font-size: large;
}   
header .onlinequotation a {
  font-size: large;
}   

@media (min-width: 1025px) {  
  header .phone a {
    font-size: x-large;
  }   
  header .onlinequotation a {
    font-size: x-large;
  }   
}

header .socialmedia svg {
  width: 16px;
}

header .navbar {
  background-color: rgb(75, 75, 75);
  /* background-color: rgb(0.8, 5, 61); */
  padding: 0.5em 1em;
}

header .navbar a {
  font-size: .8em;
  color: white;
  font-weight: 600;
}

@media (min-width: 1025px) {
  header .navbar a {
    font-size: 1em;
  }
}

header .navbar-toggler {
  color: white;
}

header .navbar .container {
  padding: 0 .8em;
}

header .nav-link {
  margin: 0 .5em;
  padding: 0;
}

header ul a:hover {
  color: orange;
}

header .dropdown-menu a {
  color: #343a40;
}

header .dropdown-menu a:hover {
  color: orange;
}

header .active > a {
  color: orange;
}

header li > a:after {
  content: "";
  /* display: block; */
  height: 1.5px;
  /* background: orange; */
  /* transform: scaleX(0); */
  transition: transform 0.5s;
}

header li > a:hover:after {
  transform: scaleX(1);
  transition: transform 0.3s;
}

header .dropdown:hover > .dropdown-menu {
  display: block;
}